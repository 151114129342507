import ChangeType from "./changeType/ChangeType";
import ActionType from "./actionType/ActionTypeReducer";
import ReturnPoint from "./returnPoint/ReturnPoint";
import Translation from "./translation/TranslationReducer";
import User from "./user/UserReducer";
import Trailer from "./trailer/TrailerReducer";
import Session from "./session/SessionReducer";
import Ticket from "./ticket/TicketReducer";
import Address from "./address/AddressReducer";
import Damage from "./damage/DamageReducer";
import Messages from "./messages/MessageReducer";
import PromiseReducer from "./promise/PromiseReducer";
import Order from "./order/OrderReducer";
import Shipment from "./shipment/ShipmentReducer";
import Inspection from "./inspection/InspectionReducer";
import Debtor from "./debtor/DebtorReducer";
import Smartview from "./smartview/SmartviewReducer";
import Tasks from "./task/TasksReducer";
import Settings from "./settings/SettingsReducer";
import Dashboard from "./dashboard/DashboardReducer";
import PartnerTicket from "./partner/PartnerTicketReducer";
import PartnerOrder from "./partner/PartnerOrderReducer";
import Report from "./report/ReportReducer";
import News from "./news/NewsReducer";
import MsgHub from "./msghub/MsgHubReducer";

const REDUCERS = [
    ActionType,
    ChangeType.Reducer,
    ReturnPoint.Reducer,
    PromiseReducer,
    Messages,
    Tasks,
    Session,
    Smartview,
    User,
    Translation,
    Trailer,
    Ticket,
    Order,
    Address,
    Damage,
    Inspection,
    Shipment,
    Debtor,
    Settings,
    Dashboard,
    PartnerTicket,
    PartnerOrder,
    Report,
    News,
    MsgHub
];

export default REDUCERS;
