import config from "../config";

class UriList {
    static CORE = "/core";
    //Dashboard endpoints
    static DASHBOARD = "/dashboard." + config.STACK_NAME;
    static DASHBOARD_TICKET = UriList.DASHBOARD + "/ticket";
    static DASHBOARD_ORDER = UriList.DASHBOARD + "/order";
    static DASHBOARD_INSPECTION = UriList.DASHBOARD + "/inspection";
    static DASHBOARD_DEBTOR = UriList.DASHBOARD + "/debtor";
    static DASHBOARD_TRAILER = UriList.DASHBOARD + "/trailer";
    //Dashboard via core endpoints
    static DASHBOARD_CORE = "/core." + config.STACK_NAME + "/dashboard";
    static DASHBOARD_TM = UriList.DASHBOARD_CORE + "/tm";
    static DASHBOARD_WS = UriList.DASHBOARD_CORE + "/ws";
    //Translation endpoints
    static TRANSLATION = "/translator." + config.STACK_NAME;
    static TRANSLATION_TRANSLATION = UriList.TRANSLATION + "/translation";
    static TRANSLATION_LANGUAGE = UriList.TRANSLATION + "/language";
    //User endpoints
    static USER = "/user." + config.STACK_NAME;
    static USER_USER = UriList.USER + "/user";
    static USER_ROLE = UriList.USER + "/role";
    static USER_CONTACT = UriList.USER + "/contact";
    static USER_TYPE = UriList.USER + "/type";
    static USER_LOGIN = UriList.USER + "/login";
    static USER_PASSWORD = UriList.USER + "/passwd";
    //News endpoints
    static NEWS = "/core." + config.STACK_NAME + "/news";
    static NEWS_NEWS = UriList.NEWS;
    //Tasks endpoints
    static TASKS = "/core." + config.STACK_NAME + "/tasks";
    static TASKS_TASKS = UriList.TASKS;
    static TASKS_NEW_TASKS = UriList.TASKS + "/isNew";
    //Trailer endpoints
    static TRAILER = "/trailer." + config.STACK_NAME;
    static TRAILER_TRAILER = UriList.TRAILER + "/trailer";
    static TRAILER_TRAILER_SEARCH = UriList.TRAILER_TRAILER + "/search";
    static TRAILER_TIRE_DAMAGE = UriList.TRAILER + "/trailer";
    static TRAILER_TIMELINE = UriList.TRAILER + "/timeline";
    static TRAILER_TRAILERS = UriList.TRAILER + "/trailers";
    static TRAILER_TRAILERTYPE = UriList.TRAILER + "/trailertype";
    static TRAILER_INSPECTIONCOUNTRY = UriList.TRAILER + "/inspection/country";
    static TRAILER_TIREPROPERTIES = UriList.TRAILER + "/tireproperties";
    //Ticket endpoints
    static TICKET = "/ticket." + config.STACK_NAME;
    static TICKET_TICKET = UriList.TICKET + "/ticket";
    static TICKET_TICKETSTATE = UriList.TICKET + "/ticketstate";
    //Order endpoints
    static ORDER = "/order." + config.STACK_NAME;
    static ORDER_SMARTVIEW = UriList.ORDER + "/smartview";
    static ORDER_ORDER = UriList.ORDER + "/order";
    static ORDER_ORDER_SHORT = UriList.ORDER_ORDER + "/short";
    static ORDER_ORDER_CREATE = UriList.ORDER_ORDER + "/create";
    static ORDER_ORDER_STATE = UriList.ORDER_ORDER + "/state";
    static ORDER_ORDER_TYPE = UriList.ORDER_ORDER + "/type";
    //Inspection endpoints
    static INSPECTION = "/inspection." + config.STACK_NAME;
    static INSPECTION_INSPECTION = UriList.INSPECTION + "/inspection";
    static INSPECTION_INSPECTION_STATE = UriList.INSPECTION_INSPECTION + "/state";
    static INSPECTION_SW2000 = UriList.INSPECTION + "/sw2000";
    //Damage endpoints
    static DAMAGE = "/damage." + config.STACK_NAME;
    static DAMAGE_CORE = "/core." + config.STACK_NAME + "/damage";
    static DAMAGE_TRAILER = UriList.DAMAGE_CORE + "/trailer";
    static DAMAGE_DAMAGE = UriList.DAMAGE + "/damage";
    static DAMAGE_DAMAGESTATE = UriList.DAMAGE + "/damagestate";
    static DAMAGE_DAMAGETYPE = UriList.DAMAGE + "/damagetype";
    static DAMAGE_NO_REPAIR = UriList.DAMAGE + "/damages/close";
    //Shipment endpoints
    static SHIPMENT = "/shipment." + config.STACK_NAME;
    static SHIPMENT_SHIPMENT = UriList.SHIPMENT + "/shipment";
    static SHIPMENT_POSITION_TRAILER = UriList.SHIPMENT + "/position/trailer/";
    static SHIPMENT_SEARCH = UriList.SHIPMENT + "/search/trailer/";
    //Address endpoints
    static ADDRESS = "/address." + config.STACK_NAME;
    static ADDRESS_ADDRESS = UriList.ADDRESS + "/address";
    static ADDRESS_ADDRESS_SEARCH = UriList.ADDRESS_ADDRESS + "/search";
    static ADDRESS_ADDRESS_SEARCH_LKWW = UriList.ADDRESS_ADDRESS + "/search/lkww";
    static ADDRESS_CREDIT_RATING = UriList.CORE + "/address/creditRating";
    static ADDRESS_BUSINESS = UriList.ADDRESS + "/business";
    static ADDRESS_CONTACT = UriList.ADDRESS + "/contact";
    static ADDRESS_CONTACT_SEARCH = UriList.ADDRESS_CONTACT + "/search";
    static ADDRESS_CONTACT_SEARCH_LKWW = UriList.ADDRESS_CONTACT + "/search/lkww";
    static ADDRESS_COUNTRY = UriList.ADDRESS + "/country";
    static ADDRESS_CURRENCY = UriList.ADDRESS + "/currency";
    static ADDRESS_BUSINESS_HOURS = UriList.CORE + "/address/business_hours";
    static ADDRESS_NOTE = UriList.CORE + "/address/note";
    static ADDRESS_AGREEMENT_OVERVIEW = UriList.CORE + "/address/agreement/overview";
    static ADDRESS_AGREEMENT = UriList.CORE + "/address/agreement";
    //Debtor endpoints
    static DEBTOR = "/debtor." + config.STACK_NAME;
    static DEBTOR_DEBTOR = UriList.DEBTOR + "/debtor";
    static DEBTOR_DEBTOR_TRAILER = UriList.DEBTOR_DEBTOR + "/trailer";
    static DEBTOR_STATE = UriList.DEBTOR_DEBTOR + "/state";
    static DEBTOR_DEBTOR_CREATE = UriList.DEBTOR_DEBTOR + "/create";
    //Report endpoints
    static REPORT = "/core." + config.STACK_NAME + "/report";
    static REPORT_TRAILER = UriList.REPORT + "/trailer";
    static REPORT_REPORT = UriList.REPORT + "/damage";
    static REPORT_REPORTEXCEL = UriList.REPORT + "/damage/excel";
    //Event endpoints
    static EVENT = "/event." + config.STACK_NAME;
    static EVENT_EVENT = UriList.EVENT + "/event";
    //File endpoint
    static FILE = "/file." + config.STACK_NAME;
    static FILE_PROCESS = UriList.FILE + "/process";
    static FILE_FILE = UriList.FILE + "/file";
    static FILE_TOKEN = UriList.FILE + "/token";
    static FILE_DOCTYPE = UriList.FILE + "/doctype";
    //Chat endpoint
    static CHAT = "/chat." + config.STACK_NAME;
    static CHAT_CHAT = UriList.CHAT + "/chat";
    static CHAT_NEW = "/core." + config.STACK_NAME + "/chat/isNew";

    //Watch
    static WATCH = "/core." + config.STACK_NAME + "/watch";
    static WATCH_IS_SUBSCRIBED = UriList.WATCH + "/subscribe";
    static WATCH_SUBSCRIBE = UriList.WATCH + "/subscribe";
    static WATCH_UNSUBSCRIBE = UriList.WATCH + "/unsubscribe";
    static WATCH_IS_NEW = UriList.WATCH + "/isNew";
    static WATCH_MARK_AS_READ = UriList.WATCH + "/markAsRead";

    //Setting endpoint
    static SETTING = "/setting." + config.STACK_NAME;
    static SETTING_FEATURE = UriList.SETTING + "/feature";
    static SETTING_DOCUMENT = UriList.SETTING + "/document";
    static SETTING_INSPECTION = UriList.SETTING + "/inspection";
    static SETTING_TIRE_BRAND = UriList.SETTING + "/brand";
    static SETTING_TIRE_PARTNER_REASON = UriList.SETTING + "/reason/mapping";
    static SETTING_TIRE_FLEET_REASON = UriList.SETTING + "/reason";
    static SETTING_DELIVER_METHOD = UriList.SETTING + "/value/orderDeliveryMethod";
    static SETTING_DAMAGE_CLOSE = UriList.SETTING + "/value/damageClose";
    static SETTING_ORDER_CLOSE = UriList.SETTING + "/value/orderClose";
    static SETTING_TICKET_CLOSE = UriList.SETTING + "/value/ticketClose";
    static SETTING_CREDIT_RATING_LIMIT = UriList.SETTING + "/value/creditRatingLimit";
    static SETTING_CREDIT_RATING_FUEL_CARD = UriList.SETTING + "/value/creditRatingFuelCard";
    static SETTING_SETTING = UriList.SETTING + "/store";
    static SETTING_TRAILER4SALE = UriList.SETTING + "/trailer4Sale";
    //Overview endpoint
    static OVERVIEW = "/overview." + config.STACK_NAME;
    static EMAIL = "/exporter-zdom." + config.STACK_NAME;
    static EMAIL_FILE = UriList.EMAIL + "/email";
    //Partner endpoints
    static PARTNER = "/pvm." + config.STACK_NAME;
    static PARTNER_TICKETS = UriList.PARTNER + "/tickets";
    static PARTNER_NEW_TICKETS = UriList.PARTNER + "/tickets/new";
    static PARTNER_TICKET = UriList.PARTNER + "/ticket";
    static PARTNER_ORDERS = UriList.PARTNER + "/orders";
    static PARTNER_ORDER = UriList.PARTNER + "/order";
    static PARTNER_DAMAGE = UriList.PARTNER + "/damage";
    static PARTNER_DAMAGE_TYPES = UriList.PARTNER_DAMAGE + "/types";
    static PARTNER_CHAT = UriList.PARTNER + "/chat";
    static PARTNER_CHAT_NEW = UriList.PARTNER + "/chat/isNew";
    //ZDOM endpoints
    static ZDOM = "/zdom." + config.STACK_NAME;
    static ZDOM_ZDOM = UriList.ZDOM + "/zdom";
    static WS_CORE = "/ws";
    static MONITOR_STATUS = "/monitor." + config.STACK_NAME + "/state";
    static MONITOR_OVERVIEW = "/monitor." + config.STACK_NAME + "/overview";
    static MONITOR_DETAIL = "/monitor." + config.STACK_NAME + "/check";

    static TRAILER_TRAILER_SET_REPAIR = (uuid) =>
        UriList.TRAILER_TRAILER + "/" + uuid + "/setrepair";

    static TRAILER_TRAILER_RETIRE = (uuid) => UriList.TRAILER_TRAILER + "/" + uuid + "/retire";

    static TRAILER_TRAILER_DAMAGES = (uuid) => UriList.TRAILER_TRAILER + "/" + uuid + "/damages";

    static TRAILER_TIRE_EVENTS = (uuid) => UriList.TRAILER_TRAILER + "/" + uuid + "/tires/history";

    static TICKET_TICKET_NO_REPAIR = (uuid) => UriList.TICKET_TICKET + "/" + uuid + "/norepair";

    static TICKET_TICKET_REPAIR_LATER = (uuid) =>
        UriList.TICKET_TICKET + "/" + uuid + "/repairlater";

    static TICKET_TICKET_CHANGE_LOCATION = (uuid) =>
        UriList.TICKET_TICKET + "/" + uuid + "/changelocation";

    static TICKET_TICKET_FORWARD_FM = (uuid) => UriList.TICKET_TICKET + "/" + uuid + "/forwardfm";

    static TICKET_TICKET_FORWARD_TM = (uuid) => UriList.TICKET_TICKET + "/" + uuid + "/forwardtm";

    static TICKET_TICKET_REOPEN = (uuid) => UriList.TICKET_TICKET + "/" + uuid + "/reopen";

    static ORDER_ORDER_TIRES = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/tires";

    static ORDER_ORDER_SEND = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/send";

    static ORDER_ORDER_REPORT = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/report";

    static ORDER_ORDER_ABPTOTP = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/abptotp";

    static ORDER_ORDER_SERVICEPARNERTOTP = (uuid) =>
        UriList.ORDER_ORDER + "/" + uuid + "/servicepartnertotp";

    static ORDER_ORDER_REPAIR_BEGIN = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/repairbegin";

    static ORDER_ORDER_REPAIR_DONE = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/repairdone";

    static ORDER_ORDER_APPROVAL = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/approval";

    static ORDER_ORDER_APPROVED = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/approved";

    static ORDER_ORDER_RECLAIM = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/reclaim";

    static ORDER_ORDER_CLOSE = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/close";

    static ORDER_ORDER_CANCEL = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/cancel";

    static ORDER_ORDER_REOPEN = (uuid) => UriList.ORDER_ORDER + "/" + uuid + "/reopen";

    static INSPECTION_INSPECTION_REPORT = (uuid) =>
        UriList.INSPECTION_INSPECTION + "/" + uuid + "/report";

    static INSPECTION_INSPECTION_RESET = (uuid) =>
        UriList.INSPECTION_INSPECTION + "/" + uuid + "/reset";

    static DEBTOR_DEBTOR_ADD_DAMAGE = (uuid) => UriList.DEBTOR_DEBTOR + "/" + uuid + "/damage";

    static DEBTOR_DEBTOR_SEND_HH = (uuid) => UriList.DEBTOR_DEBTOR + "/" + uuid + "/hh";

    static DEBTOR_DEBTOR_IN_PROGRESS = (uuid) => UriList.DEBTOR_DEBTOR + "/" + uuid + "/inprogress";

    static DEBTOR_DEBTOR_UNSUCCESSFUL = (uuid) =>
        UriList.DEBTOR_DEBTOR + "/" + uuid + "/unsuccessful";

    static DEBTOR_DEBTOR_CLOSE = (uuid) => UriList.DEBTOR_DEBTOR + "/" + uuid + "/close";

    static DEBTOR_DEBTOR_CANCEL = (uuid) => UriList.DEBTOR_DEBTOR + "/" + uuid + "/cancel";

    static REPORT_REPORTEXCELPROCESS = (uuid) => UriList.REPORT + "/damage/excel/" + uuid;

    static SETTING_DOCUMENT_TYPE = (uuid) => UriList.SETTING_DOCUMENT + "/" + uuid + "/type";

    static OVERVIEW_TARGET = (target, uuid) => UriList.OVERVIEW + "/" + target + "/" + uuid;

    static PARTNER_TICKET_CREATE_ORDER = (ticketID) =>
        UriList.PARTNER_TICKET + "/" + ticketID + "/create-order";

    static PARTNER_TICKET_CLOSE_WITHOUT_REPAIR = (ticketID) =>
        UriList.PARTNER_TICKET + "/" + ticketID + "/close-without-repair";

    static PARTNER_ORDER_SEND = (orderID) => UriList.PARTNER_ORDER + "/" + orderID + "/send";

    static PARTNER_ORDER_COPY = (orderID) => UriList.PARTNER_ORDER + "/" + orderID + "/copy";

    static PARTNER_ORDER_REPAIR_DONE = (orderID) =>
        UriList.PARTNER_ORDER + "/" + orderID + "/repair-done";

    static PARTNER_ORDER_RECLAIM = (orderID) => UriList.PARTNER_ORDER + "/" + orderID + "/reclaim";

    static PARTNER_ORDER_CLOSE = (orderID) => UriList.PARTNER_ORDER + "/" + orderID + "/close";

    static PARTNER_ORDER_CANCEL = (orderID) => UriList.PARTNER_ORDER + "/" + orderID + "/cancel";

    static PARTNER_ORDER_SEND_ASSIGNMENT_MAIL = (orderID) =>
        UriList.PARTNER_ORDER + "/" + orderID + "/send-assignment-mail";

    static PARTNER_ORDER_SEND_REQUEST_INVOICE_MAIL = (orderID) =>
        UriList.PARTNER_ORDER + "/" + orderID + "/send-request-invoice-mail";

    static PARTNER_ORDER_SEND_BILLING_INTERNAL_MAIL = (orderID) =>
        UriList.PARTNER_ORDER + "/" + orderID + "/send-billing-internal-mail";

    static PARTNER_ORDER_SEND_BILLING_EXTERNAL_MAIL = (orderID) =>
        UriList.PARTNER_ORDER + "/" + orderID + "/send-billing-external-mail";

    static PARTNER_ORDER_SEND_ADDRESS_MAIL = (orderID) =>
        UriList.PARTNER_ORDER + "/" + orderID + "/send-address-mail";

    static PARTNER_CHAT_INTERNAL = (type, id) =>
        UriList.PARTNER_CHAT + "/" + type + "/" + id + "/internal";

    static PARTNER_CHAT_PUBLIC = (type, id) =>
        UriList.PARTNER_CHAT + "/" + type + "/" + id + "/public";

    static PARTNER_HISTORY = (type, id) => UriList.PARTNER + "/" + type + "/" + id + "/history";

    static ZDOM_ZDOM_RESEND = (documentID) => UriList.ZDOM_ZDOM + "/" + documentID + "/resend";
}

export default UriList;
