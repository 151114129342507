import DefaultStoreUtil from "../../util/DefaultStoreUtil";
import MsgHubAction from "./MsgHubAction";
import MsgHubDefinition from "./MsgHubDefinition";

const MsgHub = {
    msgHub: DefaultStoreUtil.defaultReducerFunction(
        MsgHubAction.CATEGORY,
        MsgHubDefinition.defaultState
    )
};

export default MsgHub;
