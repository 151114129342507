//@ts-check
import React from "react";
import {withNamespaces as translate} from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";
import {observeStore, sessionSelectionFunction} from "./util/StoreUtil";
import ChangeType from "./store/changeType/ChangeType";
import {Store} from "./store/Store";
import SessionAction from "./store/session/SessionAction";
import SessionDefinition from "./store/session/SessionDefinition";
import {Features, hasPermission, isJWTDev, Permissions} from "./util/PermissionUtil";
import SessionLanguageSelector from "./components/common/fields/SessionLanguageSelector";
import config from "./config";
import TaskManager from "./components/task/TaskManager";
// import {ProgressSpinner} from "primereact/progressspinner";
import DateUtil from "./util/DateUtil";
import ProfileDialog from "./components/common/dialogs/DialogProfile";
import {AppMode} from "./AppMode";
import RedirectUtil from "./util/RedirectUtil";
import WatchRequest from "./requests/WatchRequest";

class AppTopbar extends React.Component {
    static defaultProps = {
        onMenuButtonClick: null,
        onTopbarMenuButtonClick: null,
        onTopbarItemClick: null,
        topbarMenuActive: false,
        activeTopbarItem: null,
        onRightPanelButtonClick: null,
        onChangePasswordItemClick: null,
        onBackToDesktopItemClick: null,
        onReportBugItemClick: null,
        isSmartView: false
    };

    static propTypes = {
        t: PropTypes.func,
        onMenuButtonClick: PropTypes.func.isRequired,
        onTopbarMenuButtonClick: PropTypes.func.isRequired,
        onTopbarItemClick: PropTypes.func.isRequired,
        topbarMenuActive: PropTypes.bool.isRequired,
        activeTopbarItem: PropTypes.string,
        onRightPanelButtonClick: PropTypes.func.isRequired,
        onChangePasswordItemClick: PropTypes.func.isRequired,
        onBackToDesktopItemClick: PropTypes.func.isRequired,
        onReportBugItemClick: PropTypes.func.isRequired,
        isSmartView: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            userEmail: "",
            userRole: 0,
            userUUID: "",
            userName: "",
            socket: null,
            lastWsState: null,
            mgmtTasks: [],
            loadingNewTasks: false,
            loadingNewChats: false,
            loadingNewPMChats: false,
            chatCount: 0,
            pmChatCount: 0,
            msgHub: {
                watchList: [],
                chats: [],
                pmChats: [],
                tasks: [],
                wsState: null
            }
        };
        this.store = observeStore(sessionSelectionFunction, this.onStoreChange, ChangeType.UPDATE);
        this.taskStore = observeStore(
            (state) => state.tasks,
            (newState) => this.setState({task: {...newState}}),
            ChangeType.UPDATE
        );
        this.msgHubStore = observeStore(
            (state) => state.msgHub,
            (newState) => this.setState({msgHub: {...newState}}),
            ChangeType.UPDATE
        );
    }

    componentDidMount() {
        // this.interval = setInterval(this.wsConnect, 2000);
        // this.interval = setInterval(this.wsPing, 60000);
    }

    // wsPing = () => {
    //     if (this.socket && this.state.lastWsState === 1) {
    //         this.socket.send(
    //             JSON.stringify({
    //                 msg: "ping",
    //                 client: {
    //                     uuid: this.state.userUUID,
    //                     role: this.state.userRole
    //                 }
    //             })
    //         );
    //     }
    // };

    // wsConnect = () => {
    //     const wsState = this.wsState();
    //     console.log("ws state: " + wsState);
    //     if (this.socket === undefined || wsState === "CLOSED") {
    //         this.socket = new WebSocket(config.WS_URL + UriList.WS_CORE);
    //         this.socket.onopen = () => {
    //             this.socket.send(
    //                 JSON.stringify({
    //                     msg: "connect",
    //                     client: {
    //                         uuid: this.state.userUUID,
    //                         role: this.state.userRole
    //                     }
    //                 })
    //             );
    //         };
    //         this.socket.onmessage = (e) => {
    //             const msg = e.data ? JSON.parse(e.data) : "";
    //             switch (msg.msg) {
    //                 case "tasksReload":
    //                     if (!this.state.loadingNewTasks) {
    //                         this.setState({loadingNewTasks: true}, () => this.setTasks());
    //                     } else {
    //                         console.warn("taskReload skip, already loading");
    //                     }
    //                     break;
    //                 case "chatReload":
    //                     if (!this.state.loadingNewChats) {
    //                         this.setState({loadingNewChats: true}, () => this.setChats());
    //                     } else {
    //                         console.warn("chatReload skip, already loading");
    //                     }
    //                     break;
    //                 case "pmChatReload":
    //                     if (!this.state.loadingNewPMChats) {
    //                         this.setState({loadingNewPMChats: true}, () => this.setPMChats());
    //                     } else {
    //                         console.warn("chatPMReload skip, already loading");
    //                     }
    //                     break;
    //                 case "reloadWatchList":
    //                     if (!this.state.loadingNewWatchList) {
    //                         this.setState({loadingNewWatchList: true}, () => this.setWatchList());
    //                     } else {
    //                         console.warn("reloadWatchList skip, already loading");
    //                     }
    //                     break;
    //                 case "pong":
    //                     break;
    //                 default:
    //             }
    //         };
    //     }
    // };

    wsStateColor() {
        switch (this.state.msgHub.wsState) {
            case "CONNECTING":
                return "yellow";
            case "OPEN":
                return "white";
            case "CLOSING":
                return "orange";
            default:
                return "red";
        }
    }

    // wsState() {
    //     if (this.socket) {
    //         if (this.state.lastWsState === this.socket.readyState) {
    //             return this.state.lastWsState;
    //         }
    //         switch (this.socket.readyState) {
    //             case 0:
    //                 this.setState({
    //                     lastWsState: this.socket.readyState,
    //                     wsStateColor: "yellow",
    //                     wsState: "CONNECTING"
    //                 });
    //                 break;
    //             case 1:
    //                 this.setState({
    //                     lastWsState: this.socket.readyState,
    //                     wsStateColor: "white",
    //                     wsState: "OPEN"
    //                 });
    //                 break;
    //             case 2:
    //                 this.setState({
    //                     lastWsState: this.socket.readyState,
    //                     wsStateColor: "orange",
    //                     wsState: "CLOSING"
    //                 });
    //                 break;
    //             default:
    //                 this.setState({
    //                     lastWsState: this.socket.readyState,
    //                     wsStateColor: "red",
    //                     wsState: "CLOSED"
    //                 });
    //         }
    //     }
    //     return this.state.wsState;
    // }

    // setTasks() {
    //     this.loadTasks().then((data) => {
    //         this.setState({
    //             mgmtTasks: data,
    //             loadingNewTasks: false
    //         });
    //     });
    // }

    // setChats() {
    //     this.loadChats().then((data) => {
    //         this.setState({
    //             chats: data,
    //             loadingNewChats: false,
    //             chatCount: data && data.length > 0 ? data.length : 0
    //         });
    //     });
    // }

    // setPMChats() {
    //     this.loadPMChats().then((data) => {
    //         this.setState({
    //             pmChats: data,
    //             loadingNewPMChats: false,
    //             pmChatCount: data && data.length > 0 ? data.length : 0
    //         });
    //     });
    // }

    // setWatchList() {
    //     this.loadWatchList().then((data) => {
    //         if (data && data.payload && data.payload.length > 0) {
    //             this.setState({
    //                 watchList: data.payload,
    //                 loadingNewWatchList: false,
    //                 watchListCount:
    //                     data.payload && data.payload.length > 0
    //                         ? data.payload.filter((w) => w.messageLabel === "order_stateRepairDone")
    //                               .length
    //                         : 0
    //             });
    //         }
    //     });
    // }

    countChats() {
        const watchList = this.state.msgHub.watchList;
        const countWatchList =
            watchList.length > 0
                ? watchList.filter((w) => w.messageLabel === "order_stateRepairDone").length
                : 0;
        const chats = this.state.msgHub.chats;
        const pmChats = this.state.msgHub.pmChats;
        const chatsCount = chats && chats.length > 0 ? chats.length : 0;
        const omChatsCount = pmChats && pmChats.length > 0 ? pmChats.length : 0;
        return chatsCount + omChatsCount + countWatchList;
    }

    // eslint-disable-next-line consistent-return
    // async loadTasks() {
    //     try {
    //         return await TasksRequest.loadNewTasks();
    //     } catch (err) {
    //         ErrorUtil.handleError("msg_entriesLoadFailed", err, false);
    //     }
    // }

    // // eslint-disable-next-line consistent-return
    // async loadChats() {
    //     try {
    //         return await ChatRequest.loadNewChats(6, this.state.userUUID, this.state.userRole);
    //     } catch (err) {
    //         ErrorUtil.handleError("msg_entriesLoadFailed", err, false);
    //     }
    // }

    // eslint-disable-next-line consistent-return
    // async loadPMChats() {
    //     try {
    //         return await ChatRequest.loadNewPVMChats(6, this.state.userUUID, this.state.userRole);
    //     } catch (err) {
    //         ErrorUtil.handleError("msg_entriesLoadFailed", err, false);
    //     }
    // }

    // eslint-disable-next-line consistent-return
    // async loadWatchList() {
    //     try {
    //         return await WatchRequest.isNew(this.state.userUUID);
    //     } catch (err) {
    //         ErrorUtil.handleError("msg_entriesLoadFailed", err, false);
    //     }
    // }

    onTopbarItemClick(event, item) {
        if (this.props.onTopbarItemClick) {
            this.props.onTopbarItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

    onLogoutItemClick() {
        Store.dispatch(
            SessionAction.create(
                ChangeType.UPDATE,
                SessionAction.LOGOUT,
                SessionDefinition.defaultState
            )
        );
    }

    onStoreChange = (state) => {
        if (state !== null && state.jwt !== undefined) {
            this.setState({
                userEmail: state.email,
                userRole: state.role,
                userUUID: state.user,
                userName: (state.lastname || "") + (state.firstname ? " " + state.firstname : "")
            });
        }
    };

    onMgmtTaskClick = (task) => {
        RedirectUtil.openInNewWindow(
            RedirectUtil.pathList.DATA_MANAGEMENT_TASKS,
            task.internalID,
            RedirectUtil.ACTIONTYPE.EDIT
        );
    };

    onWatchClick = (watch) => {
        const path = RedirectUtil.pathList.LKWWALTER_ORDER_DETAIL;
        const uuid = watch.objectUUID;
        RedirectUtil.redirect(path, uuid, RedirectUtil.ACTIONTYPE.VIEW);
    };

    onChatClick = (chat) => {
        const loc = window.location.href;
        let uuid;
        let path;
        switch (chat.reference) {
            case "order":
                path = RedirectUtil.pathList.LKWWALTER_ORDER_DETAIL;
                uuid = chat.conversationUUID;
                break;
            case "ticket":
                path = RedirectUtil.pathList.LKWWALTER_TICKET_DETAIL;
                uuid = chat.conversationUUID;
                break;
            case "pvm-order":
                path = RedirectUtil.pathList.PARTNER_ORDER_DETAIL;
                uuid = chat.refID;
                break;
            case "pvm-ticket":
                path = RedirectUtil.pathList.PARTNER_TICKET_DETAIL;
                uuid = chat.refID;
                break;
            default:
                return;
        }
        if (loc.includes(uuid)) {
            RedirectUtil.redirect(path, uuid, RedirectUtil.ACTIONTYPE.CHAT);
        } else {
            RedirectUtil.openInNewWindow(path, uuid, RedirectUtil.ACTIONTYPE.CHAT);
        }
    };

    taskCount() {
        let count = 0;
        if (this.state.task && this.state.task.activeTasks) {
            count += this.state.task.activeTasks;
        }
        if (this.state.msgHub.tasks) {
            if (this.state.msgHub.tasks.length > 0) {
                count += this.state.msgHub.tasks.length;
            }
        }
        return count;
    }

    randomKey() {
        return Math.floor(Math.random() * (10000 - 1) + 1);
    }

    // render the watchlist in the notification overlay
    renderWatchList() {
        const t = this.props.t;
        const watchList = this.state.msgHub.watchList;
        return (
            <>
                <div className="f-topbar-task-title">{this.props.t("gen_watch") + ":"}</div>
                {watchList.map((watch) => (
                    <li key={this.randomKey()} role="menuitem">
                        <div className="f-topbar-task-item">
                            <div className="f-topbar-task-item-text">
                                <i
                                    className="material-icons"
                                    style={{
                                        verticalAlign: "bottom",
                                        paddingTop: "1px"
                                    }}
                                >
                                    visibility
                                </i>
                                <div
                                    style={{
                                        display: "inline",
                                        marginLeft: "5px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => this.onWatchClick(watch)}
                                >
                                    {watch.objectID} / {t(watch.messageLabel)}
                                </div>
                                <div
                                    title={"Mark as read"}
                                    className="material-icons"
                                    style={{
                                        verticalAlign: "bottom",
                                        paddingTop: "1px",
                                        cursor: "pointer",
                                        float: "right",
                                        color: "darkgray"
                                    }}
                                    onClick={() => {
                                        if (watch.messageLabel === "order_stateRepairDone") {
                                            WatchRequest.markAsRead(watch.objectUUID).then();
                                        } else {
                                            WatchRequest.unsubscribe(watch.objectUUID).then();
                                        }
                                    }}
                                >
                                    {watch.messageLabel === "order_stateRepairDone"
                                        ? "check"
                                        : "clear"}
                                </div>
                            </div>
                            <div className="f-topbar-task-item-date">
                                {DateUtil.formatDbDate(watch.updatedAt, true)}
                            </div>
                        </div>
                    </li>
                ))}
            </>
        );
    }

    render() {
        const topbarItemsClassName = classNames("topbar-items animated fadeInDown", {
            "topbar-items-visible": this.props.topbarMenuActive
        });

        const activeTasks = TaskManager.getActiveTasks();
        const mgmtTasks = this.state.msgHub.tasks;
        const chats = this.state.msgHub.chats;
        const pmChats = this.state.msgHub.pmChats;
        const watchList = this.state.msgHub.watchList;
        const t = this.props.t;

        return (
            <div className="topbar clearfix">
                <div className="topbar-left">
                    <div className="logo" />
                    {this.props.isSmartView && <div className="f-logo-smartview" />}
                </div>

                <div className="topbar-right">
                    {!this.props.isSmartView && (
                        <a href="/" id="menu-button" onClick={this.props.onMenuButtonClick}>
                            <i />
                        </a>
                    )}

                    {isJWTDev() && !this.props.isSmartView ? (
                        <a
                            href="/"
                            id="rightpanel-menu-button"
                            onClick={this.props.onRightPanelButtonClick}
                        >
                            <i className="material-icons">more_vert</i>
                        </a>
                    ) : (
                        ""
                    )}

                    <a
                        href="/"
                        id="topbar-menu-button"
                        onClick={this.props.onTopbarMenuButtonClick}
                    >
                        <i className="material-icons">menu</i>
                    </a>
                    <ul key={this.randomKey()} className={topbarItemsClassName}>
                        <li
                            key={this.randomKey()}
                            className={classNames("profile-item", {
                                "active-top-menu": this.props.activeTopbarItem === "profile"
                            })}
                        >
                            <a href="/" onClick={(e) => this.onTopbarItemClick(e, "profile")}>
                                <i className="topbar-icon material-icons">person</i>
                                <span className="topbar-item-name">{t("gen_userMenu")}</span>
                            </a>
                            <ul key={this.randomKey()} className="ultima-menu animated fadeInDown">
                                <div
                                    className={
                                        "f-profile-user" +
                                        (hasPermission(Permissions.view, Features.profile)
                                            ? " f-profile-user-button"
                                            : "")
                                    }
                                    onClick={
                                        hasPermission(Permissions.view, Features.profile)
                                            ? () =>
                                                  ProfileDialog.displayForUUID(this.state.userUUID)
                                            : undefined
                                    }
                                >
                                    <div className="f-profile-image">
                                        <i className="material-icons f-profile-icon">person</i>
                                    </div>
                                    <div className="f-profile-name">{this.state.userName}</div>
                                    <div className="f-profile-mail">{this.state.userEmail}</div>
                                </div>
                                {this.props.isSmartView && window.innerWidth > 1023 && (
                                    <li key={this.randomKey()} role="menuitem">
                                        <a
                                            href="/"
                                            className="f-profile-entry"
                                            onClick={this.props.onBackToDesktopItemClick}
                                        >
                                            <i
                                                key={this.randomKey()}
                                                className="material-icons f-profile-icon"
                                            >
                                                desktop_windows
                                            </i>
                                            <span className="f-profile-text">
                                                {this.props.t("smart_backToDesktop")}
                                            </span>
                                        </a>
                                    </li>
                                )}
                                <li key={this.randomKey()} role="menuitem">
                                    <a
                                        href="/"
                                        className="f-profile-entry"
                                        onClick={this.props.onChangePasswordItemClick}
                                    >
                                        <i className="material-icons f-profile-icon">vpn_key</i>
                                        <span className="f-profile-text">
                                            {" "}
                                            {this.props.t("gen_changePassword")}{" "}
                                        </span>
                                    </a>
                                </li>
                                <li key={this.randomKey()} role="menuitem">
                                    <a
                                        href="/"
                                        className="f-profile-entry"
                                        onClick={this.onLogoutItemClick}
                                    >
                                        <i className="material-icons f-profile-icon">
                                            power_settings_new
                                        </i>
                                        <span className="f-profile-text">
                                            {" "}
                                            {this.props.t("gen_logout")}
                                        </span>
                                    </a>
                                </li>
                                <div className="f-profile-debug-area">
                                    <li key={this.randomKey()} role="menuitem">
                                        <div className="f-profile-debug">
                                            <span className="f-debug">
                                                {this.props.t("user_version") + ": "}
                                                {config.VERSION}
                                            </span>
                                            <br />
                                            <span className="f-debug">
                                                {this.props.t("user_role") + ": "}
                                                {this.state.userRole
                                                    ? this.props.t("role_" + this.state.userRole)
                                                    : "N/A"}
                                            </span>
                                            <br />
                                            <span className="f-debug">
                                                ID: {this.state.userUUID}
                                            </span>
                                        </div>
                                    </li>
                                    {this.state.userRole === "fmAdmin" ||
                                    this.state.userRole === "developer" ? (
                                        <div>
                                            <li role="menuitem">
                                                <a href="/" className="f-profile-entry">
                                                    <i className="material-icons f-profile-icon">
                                                        report
                                                    </i>
                                                    <span className="f-profile-text">
                                                        {" "}
                                                        {this.props.t("gen_support")}:
                                                    </span>
                                                </a>
                                            </li>
                                            <li role="menuitem">
                                                <a
                                                    className="f-profile-entry"
                                                    href="sip:+436649111489"
                                                >
                                                    Tel: +43 664 9111489
                                                </a>
                                            </li>
                                            <li role="menuitem">
                                                <a
                                                    className="f-profile-entry"
                                                    href="mailto:support@fleet.it4s.at"
                                                >
                                                    Email: support@fleet.it4s.at
                                                </a>
                                            </li>
                                            <li role="menuitem">
                                                <a
                                                    className="f-profile-entry"
                                                    href="https://it4silvertip.atlassian.net/jira/software/projects/FLEET/issues"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Web: Support Portal
                                                </a>
                                            </li>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </ul>
                        </li>
                        <li
                            key={this.randomKey()}
                            className={classNames({
                                "active-top-menu": this.props.activeTopbarItem === "tasks"
                            })}
                        >
                            <a
                                href="/"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.onTopbarItemClick(e, "tasks");
                                }}
                            >
                                <i
                                    className="topbar-icon material-icons"
                                    style={{color: this.wsStateColor}}
                                >
                                    timelapse
                                </i>
                                {this.taskCount() > 0 ? (
                                    <span
                                        className="topbar-badge animated rubberBand"
                                        style={{background: "red"}}
                                    >
                                        {this.taskCount()}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <span className="topbar-item-name">Tasks</span>
                            </a>
                            {/* Tasks Menu */}
                            <ul key={this.randomKey()} className="ultima-menu animated fadeInDown">
                                <div className="f-topbar-task-title">
                                    {this.props.t("gen_tasks") + ":"}
                                </div>
                                {/* Management Task list */}
                                {(this.state.userRole === "fmAdmin" ||
                                    this.state.userRole === "fmUser" ||
                                    this.state.userRole === "developer") &&
                                mgmtTasks &&
                                mgmtTasks.length > 0
                                    ? mgmtTasks.map((task) => (
                                          <li key={task.internalID} role="menuitem">
                                              <div className="f-topbar-task-item">
                                                  <div
                                                      className="f-topbar-task-item-text"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => this.onMgmtTaskClick(task)}
                                                  >
                                                      {task.createdBy}
                                                  </div>
                                                  <div className="f-topbar-task-item-date">
                                                      {t(task.taskTypeLabel)} /{" "}
                                                      {DateUtil.formatDbDate(task.createdAt, true)}
                                                  </div>
                                              </div>
                                          </li>
                                      ))
                                    : ""}
                                {/* System Task list */}
                                <div style={{marginTop: "1em"}} />
                                {Boolean(activeTasks && activeTasks.length) &&
                                    activeTasks
                                        .slice(0, activeTasks.length > 5 ? 4 : activeTasks.length)
                                        .map((task) => (
                                            <li key={task.id} role="menuitem">
                                                <div className="f-topbar-task-item">
                                                    {/*<ProgressSpinner*/}
                                                    {/*    className="f-task-spinner"*/}
                                                    {/*    strokeWidth="7"*/}
                                                    {/*/>*/}
                                                    <div className="f-topbar-task-item-text">
                                                        {this.props.t(task.title)}
                                                    </div>
                                                    <div className="f-topbar-task-item-date">
                                                        {DateUtil.formatDateTimeSeconds(task.start)}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                {Boolean(activeTasks && activeTasks.length > 5) && (
                                    <li key={Math.random() * 100} role="menuitem">
                                        <div className="f-topbar-task-item">
                                            <div className="f-topbar-task-item-more">
                                                {activeTasks.length -
                                                    4 +
                                                    " " +
                                                    this.props.t("gen_tasksMore")}
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {!activeTasks || !activeTasks.length ? (
                                    <li key={Math.random() * 100} role="menuitem">
                                        <div className="f-topbar-task-no-item">
                                            <div className="f-topbar-task-item-text">
                                                {this.props.t("gen_noActiveSysTasks")}
                                            </div>
                                        </div>
                                    </li>
                                ) : (
                                    ""
                                )}
                                {/*<li role="menuitem">*/}
                                {/*    <a href="/" onClick={(e) => {*/}
                                {/*        e.preventDefault();*/}
                                {/*        TaskManager.openSidebar();*/}
                                {/*    }}*/}
                                {/*       className="f-topbar-task-show-all">*/}
                                {/*        <i className="material-icons">timelapse</i>*/}
                                {/*        <div>{this.props.t("gen_showAll")}</div>*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </li>
                        {/* Notifications Menu */}
                        <li
                            key={this.randomKey()}
                            className={classNames({
                                "active-top-menu": this.props.activeTopbarItem === "notifications"
                            })}
                        >
                            <a href="/" onClick={(e) => this.onTopbarItemClick(e, "notifications")}>
                                <i
                                    className="topbar-icon material-icons"
                                    style={{color: this.wsStateColor}}
                                >
                                    chat
                                </i>
                                {this.countChats() > 0 ? (
                                    <span
                                        className="topbar-badge animated rubberBand"
                                        style={{background: "red"}}
                                    >
                                        {this.countChats()}
                                    </span>
                                ) : (
                                    ""
                                )}
                                <span className="topbar-item-name">Notifications</span>
                            </a>
                            <ul key={this.randomKey()} className="ultima-menu animated fadeInDown">
                                {this.countChats() === 0 ? (
                                    <li key={this.randomKey()} role="menuitem">
                                        <div className="f-topbar-task-no-item">
                                            <div className="f-topbar-task-item-text">
                                                {this.props.t("gen_noNewChats")}
                                            </div>
                                        </div>
                                    </li>
                                ) : (
                                    ""
                                )}
                                {chats || (pmChats && chats.length + pmChats.length > 0) ? (
                                    <div className="f-topbar-task-title">
                                        {this.props.t("gen_chats") + ":"}
                                    </div>
                                ) : (
                                    ""
                                )}
                                {chats && chats.length > 0
                                    ? chats.map((chat) => (
                                          <li key={this.randomKey()} role="menuitem">
                                              <div className="f-topbar-task-item">
                                                  <div
                                                      className="f-topbar-task-item-text"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => this.onChatClick(chat)}
                                                  >
                                                      <i
                                                          className="material-icons"
                                                          style={{verticalAlign: "bottom"}}
                                                      >
                                                          {chat.reference === "order"
                                                              ? "shop"
                                                              : "assignment"}
                                                      </i>
                                                      {chat.refID} / {chat.creUserName}
                                                  </div>
                                                  <div className="f-topbar-task-item-date">
                                                      {DateUtil.formatDbDate(chat.creDat, true)}
                                                  </div>
                                              </div>
                                          </li>
                                      ))
                                    : ""}
                                {pmChats && pmChats.length > 0
                                    ? pmChats.map((pmChat) => (
                                          <li key={this.randomKey()} role="menuitem">
                                              <div className="f-topbar-task-item">
                                                  <div
                                                      className="f-topbar-task-item-text"
                                                      style={{cursor: "pointer"}}
                                                      onClick={() => this.onChatClick(pmChat)}
                                                  >
                                                      <i
                                                          className="material-icons"
                                                          style={{verticalAlign: "bottom"}}
                                                      >
                                                          {pmChat.reference === "pvm-order"
                                                              ? "shop"
                                                              : "assignment"}
                                                      </i>
                                                      {pmChat.refID} / {pmChat.creUserName}
                                                  </div>
                                                  <div className="f-topbar-task-item-date">
                                                      {DateUtil.formatDbDate(pmChat.creDat, true)}
                                                  </div>
                                              </div>
                                          </li>
                                      ))
                                    : ""}
                                {watchList && watchList.length > 0 ? this.renderWatchList() : ""}
                            </ul>
                        </li>
                        {/* <li
                            className={classNames("search-item", {
                                "active-top-menu": this.props.activeTopbarItem === "search"
                            })}
                            onClick={(e) => this.onTopbarItemClick(e, "search")}
                        >
                            <span className="md-inputfield">
                                <InputText type="text" />
                                <label>Search</label>
                                <i className="topbar-icon material-icons">search</i>
                            </span>
                        </li> */}
                    </ul>
                    <AppMode>jwt = this.state.jwt</AppMode>
                    <SessionLanguageSelector />
                    <div className="topbar-info">
                        <div className="topbar-info-container">
                            <div className="topbar-info-test">! Testumgebung !</div>
                            {isJWTDev() && (
                                <div className="topbar-info-dev">
                                    <div className="topbar-dev-title">
                                        Developer-Account - Viewing as:
                                    </div>
                                    <div className="topbar-dev-user">
                                        <div>User:</div>
                                        <div className="topbar-dev-value">
                                            {this.state.userName}
                                        </div>
                                    </div>
                                    <div className="topbar-dev-role">
                                        <div>Role:</div>
                                        <div className="topbar-dev-value">
                                            {this.state.userRole}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.store.unsubscribe();
        this.msgHubStore.unsubscribe();
        if (this.socket) this.socket.close();
        if (this.interval) clearInterval(this.interval);
    }
}

export default translate("fleet")(AppTopbar);
