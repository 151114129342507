import BaseService from "../BaseService";
import FeatureUtil from "../../util/features/FeatureUtil";
import LoadEntriesFeature from "../../util/features/LoadEntriesFeature";
import ChangeType from "../../store/changeType/ChangeType";
import MsgHubAction from "../../store/msghub/MsgHubAction";

class MsgHubService extends BaseService {
    static selectFunction = (state) => (state.news ? state.news : null);
    // #endregion

    // #region Features setup
    createAction = MsgHubAction.create;
    actionMap = {
        [FeatureUtil.ACTIONS.LOADING]: MsgHubAction.LOADING
    };
    // #endregion

    // #region  Set-Up Lifecycle
    constructor(props) {
        super(props, MsgHubService.selectFunction);
        LoadEntriesFeature.applyBindings(this);
    }

    onReload = () => {};

    onServiceEnd() {
        this.store.dispatch(
            MsgHubAction.create(ChangeType.UPDATE, MsgHubAction.CLEAR, {
                watchList: [],
                chats: [],
                pmChats: [],
                tasks: []
            })
        );
    }
}

export default MsgHubService;
