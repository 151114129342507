import React, {Component} from "react";
import classNames from "classnames";
import {AppFooter} from "./AppFooter";
import {AppMenu} from "./AppMenu";
import {Route} from "react-router-dom";
import {withNamespaces as translate} from "react-i18next";
import {PropTypes} from "prop-types";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import {Store} from "./store/Store";
import Login from "./AppLogin";
import SessionAction from "./store/session/SessionAction";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "fullcalendar/dist/fullcalendar.css";
import "filepond/dist/filepond.min.css";
import "./ripple.js";
import "./assets/fleet.css";
import Loadable from "react-loadable";
import {observeStore, sessionSelectionFunction} from "./util/StoreUtil";
import ChangeType from "./store/changeType/ChangeType";
import {Features, Permissions, roleIdHasPermission, Roles} from "./util/PermissionUtil";
import AppTopbar from "./AppTopbar";
import AppRightPanel from "./AppRightPanel";
import OnSiteMessages from "./components/messages/OnSiteMessages";
import GlobalMessagesDisplay from "./components/messages/GlobalMessagesDisplay";
import TestToolbox from "./components/dev/TestToolbox";
import TicketDefinition from "./store/ticket/TicketDefinition";
import DialogChangePassword from "./components/common/dialogs/DialogChangePassword";
import AddressRequest from "./requests/AddressRequest";
import {prioritizedRequest, request} from "./util/RequestUtil";
import config from "./config";
import OrderDefinition from "./store/order/OrderDefinition";
import TrailerDefinition from "./store/trailer/TrailerDefinition";
import InspectionDefinition from "./store/inspection/InspectionDefinition";
import AddressDefinition from "./store/address/AddressDefinition";
import UserDefinition from "./store/user/UserDefinition";
import TranslationDefinition from "./store/translation/TranslationDefinition";
import SessionDefinition from "./store/session/SessionDefinition";
import TicketAction from "./store/ticket/TicketAction";
import DocumentTitleUtil from "./util/DocumentTitleUtil";
import TranslationUtil from "./util/TranslationUtil";
import JSZip from "jszip";
import saveAs from "file-saver";
import ConsoleUtil from "./util/ConsoleUtil";
import moment from "moment";
import ClientJS from "clientjs";
import UriList from "./service/EndpointsList";
import {ProgressSpinner} from "primereact/progressspinner";
import DebtorDefinition from "./store/debtor/DebtorDefinition";
import RedirectUtil from "./util/RedirectUtil";
import SmartviewDefinition from "./store/smartview/SmartviewDefinition";
import SmartviewAction from "./store/smartview/SmartviewAction";
import TaskMonitor from "./components/task/TaskMonitor";
import ProfileDialog from "./components/common/dialogs/DialogProfile";
import AddressDialog from "./components/common/dialogs/DialogAddress";
import ErrorUtil from "./util/ErrorUtil";
import PartnerTicketRequest from "./requests/PartnerTicketRequest";
import TasksDefinition from "./store/task/TasksDefinition";
import {AppMsgHub} from "./AppMsgHub";

const PageUpdated = React.lazy(() => import("./PageUpdated"));

const Dashboard = Loadable({
    loader: () => import("./views/dashboard/Dashboard"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const UserOverview = Loadable({
    loader: () => import("./views/management/user/UserOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const TranslationModule = Loadable({
    loader: () => import("./views/management/translation/TranslationOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const TrailerOverview = Loadable({
    loader: () => import("./views/trailer/TrailerOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const TrailerDetail = Loadable({
    loader: () => import("./views/trailer/TrailerDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const TicketOverview = Loadable({
    loader: () => import("./views/ticket/TicketOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const TicketDetail = Loadable({
    loader: () => import("./views/ticket/TicketDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const OrderOverview = Loadable({
    loader: () => import("./views/order/OrderOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const OrderDetail = Loadable({
    loader: () => import("./views/order/OrderDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const AddressOverview = Loadable({
    loader: () => import("./views/address/AddressOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const AddressDetail = Loadable({
    loader: () => import("./views/address/AddressDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const DamageOverview = Loadable({
    loader: () => import("./views/damage/DamageOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const ShipmentOverview = Loadable({
    loader: () => import("./views/management/shipment/ShipmentOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const InspectionOverview = Loadable({
    loader: () => import("./views/inspection/InspectionOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const DebtorOverview = Loadable({
    loader: () => import("./views/debtor/DebtorOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const DebtorDetail = Loadable({
    loader: () => import("./views/debtor/DebtorDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const Report = Loadable({
    loader: () => import("./views/report/Report"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const SettingsPage = Loadable({
    loader: () => import("./views/management/SettingsPage"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const News = Loadable({
    loader: () => import("./views/management/news/NewsOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const Tasks = Loadable({
    loader: () => import("./views/management/tasks/TasksOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const SmartTrailerView = Loadable({
    loader: () => import("./views/smartview/SmartTrailerView"),
    loading: () => <ProgressSpinner />,
    delay: 0
});
const SmartOrderOverview = Loadable({
    loader: () => import("./views/smartview/SmartOrderOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});
const SmartTicketCreation = Loadable({
    loader: () => import("./views/smartview/SmartTicketCreation"),
    loading: () => <ProgressSpinner />,
    delay: 0
});
const SmartOrderDetail = Loadable({
    loader: () => import("./views/smartview/SmartOrderDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

const PartnerOrderDetail = Loadable({
    loader: () => import("./views/partner/PartnerOrderDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});
const PartnerOrderOverview = Loadable({
    loader: () => import("./views/partner/PartnerOrderOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});
const PartnerTicketDetail = Loadable({
    loader: () => import("./views/partner/PartnerTicketDetail"),
    loading: () => <ProgressSpinner />,
    delay: 0
});
const PartnerTicketOverview = Loadable({
    loader: () => import("./views/partner/PartnerTicketOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});
const MonitorOverview = Loadable({
    loader: () => import("./views/monitor/MonitorOverview"),
    loading: () => <ProgressSpinner />,
    delay: 0
});

class App extends Component {
    static propTypes = {
        t: PropTypes.func
    };

    constructor() {
        super();
        this.state = {
            loggedIn: false, //default to false
            jwt: localStorage.getItem("token"),
            loginError: "",
            layoutMode: "static",
            profileMode: "inline",
            staticMenuDesktopType: 0,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            rightPanelActive: false,
            menuActive: false,
            passwordChangeDialogVisible: false,
            languageLoaded: false,
            passwordExpired: false,
            partnerNewTicketCount: 0,
            loadingNewTicketCount: false,
            pageUpdated: false
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.onRightPanelButtonClick = this.onRightPanelButtonClick.bind(this);
        this.onRightPanelClick = this.onRightPanelClick.bind(this);
        this.onChangePasswordItemClick = this.onChangePasswordItemClick.bind(this);
        this.onReportBug = this.onReportBug.bind(this);

        //observe store
        this.store = observeStore(sessionSelectionFunction, this.onStoreChange, ChangeType.UPDATE);
    }

    componentDidMount() {
        document.documentElement.setAttribute("data-browser", navigator.userAgent);
        if (config.API_URL.includes("testfleet")) {
            document.documentElement.setAttribute("data-environment", "TEST");
        }
        RedirectUtil.createPathList();

        const browserLanguage = navigator.language ? navigator.language.split("-") : "en";
        localStorage.setItem("language", browserLanguage[0]);

        this.loadingNewTicketCount();
        //this.interval = setInterval(() => this.loadingNewTicketCount(), 30000);

        if (this.state.jwt) {
            this.setState({loading: true});
            request("/user." + config.STACK_NAME + "/login", {
                headers: {
                    Authorization: "Bearer " + this.state.jwt
                }
            })
                .then((res) =>
                    res
                        .json()
                        .then(() =>
                            Store.dispatch(
                                SessionAction.create(ChangeType.UPDATE, SessionAction.LOGIN, {
                                    jwt: this.state.jwt
                                })
                            )
                        )
                        .catch(this.clearJwt)
                )
                .catch(this.clearJwt);
        }
        window.addEventListener("beforeunload", this.beforeUnload);
        this.onHashChange({newURL: window.location.href, newHash: window.location.hash});
        window.addEventListener("hashchange", this.onHashChange);

        window.addEventListener("pageUpdated", () =>
            this.setState({pageUpdated: !window._isPopup})
        );
        if (window.__pageUpdated && !window._isPopup) {
            this.setState({pageUpdated: true});
        }
    }

    clearJwt = () => {
        localStorage.removeItem("token");
        this.setState({loading: false, jwt: null}, () => window.location.reload());
    };

    loadingNewTicketCount = () => {
        if (!this.state.loadingNewTicketCount && this.state.jwt !== null) {
            this.setState({loadingNewTicketCount: true});
            PartnerTicketRequest.loadNewTickets()
                .then((result) => {
                    this.setState({
                        partnerNewTicketCount: result.newTicketCount,
                        loadingNewTicketCount: false
                    });
                })
                .catch((err) => {
                    ErrorUtil.handleError("msg_LoadNewPartnerTicketsFailed", err, false);
                    this.setState({loadingNewTicketCount: false});
                });
        }
    };

    onMenuClick() {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        });

        if (this.isDesktop()) {
            this.setState({
                staticMenuDesktopType: (this.state.staticMenuDesktopType + 1) % 3
            });
        } else {
            this.setState({
                staticMenuMobileActive: !this.state.staticMenuMobileActive
            });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        //this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item) {
            this.setState({activeTopbarItem: null});
        } else {
            this.setState({activeTopbarItem: event.item});
        }

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            //this.hideOverlayMenu();
        }
        if (!event.item.items && this.isSlim()) {
            this.setState({
                menuActive: false
            });
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });

        event.originalEvent.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.setState({
            rightPanelActive: !this.state.rightPanelActive
        });
        event.preventDefault();
    }

    onRightPanelClick() {
        this.rightPanelClick = true;
    }

    onDocumentClick() {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isSlim()) {
                this.setState({
                    menuActive: false
                });
            }

            //this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            });
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    onBackToDesktopItemClick = (event) => {
        event.preventDefault();
        window.location = "?no-smartview/" + RedirectUtil.defaultPath.hash;
    };

    onChangePasswordItemClick = (event) => {
        event.preventDefault();
        this.setState({passwordChangeDialogVisible: true});
    };

    async onReportBug(event) {
        this.setState({bugReportOngoing: true});
        event.preventDefault();
        const archive = new JSZip();
        const reportTime = moment().format("YYYYMMDD-hhmmss");
        const fileName =
            "bugReport_" +
            reportTime +
            "_" +
            this.store.getState().lastname +
            "-" +
            this.store.getState().firstname +
            ".zip";
        const mailto = "mailto:service@styletronic.com?subject=";
        const subject = "FLEET Bug-Report " + reportTime;
        const body =
            this.props.t("bug_title") +
            "%0D%0A%0D%0A" +
            this.props.t("bug_stepsTitle") +
            "%0D%0A" +
            "1. " +
            this.props.t("bug_stepOne") +
            "%0D%0A" +
            "2. " +
            this.props.t("bug_stepTwo") +
            "%0D%0A" +
            "3. ...%0D%0A%0D%0A" +
            this.props.t("bug_expectedTitle") +
            "%0D%0A" +
            this.props.t("bug_expectedDesc") +
            "%0D%0A%0D%0A" +
            this.props.t("bug_actualTitle") +
            "%0D%0A" +
            this.props.t("bug_actualDesc") +
            "%0D%0A%0D%0A" +
            this.props.t("bug_screenshotReminder") +
            "";
        archive.file("consoleLog.txt", ConsoleUtil.getLog());
        archive.file("reduxStore.txt", JSON.stringify(Store.getState(), null, "\t"));
        archive.file("document.html", document.documentElement.outerHTML);
        archive.file("sytemInfo.txt", this.collectSystemInformation());
        Object.keys(localStorage).forEach((key) =>
            archive.file("localStorage/" + key + ".txt", localStorage.getItem(key))
        );
        archive
            .generateAsync({type: "binarystring"})
            .then((content) => {
                prioritizedRequest(UriList.FILE_PROCESS, {
                    method: "POST",
                    body:
                        'Content-Disposition: form-data; name="filepond"; filename="' +
                        fileName +
                        '"\nContent-Type: application/zip\n\n' +
                        content,
                    formdata: true
                })
                    .then((response) => {
                        RedirectUtil.triggerProtocolUrl(
                            mailto +
                                subject +
                                "&body=##########%0D%0A" +
                                fileName +
                                "%0D%0A" +
                                response +
                                "%0D%0A##########%0D%0A%0D%0A" +
                                body
                        );
                        this.setState({bugReportOngoing: false});
                    })
                    .catch(() => {
                        archive
                            .generateAsync({type: "blob"})
                            .then((blob) => {
                                saveAs(blob, fileName);
                                RedirectUtil.triggerProtocolUrl(
                                    mailto +
                                        subject +
                                        "&body=" +
                                        this.props.t("bug_attachmentReminder") +
                                        "%0D%0A%0D%0A" +
                                        body
                                );
                                this.setState({bugReportOngoing: false});
                            })
                            .catch(() => {
                                RedirectUtil.triggerProtocolUrl(mailto + subject + "&body=" + body);
                                this.setState({bugReportOngoing: false});
                            });
                    });
            })
            .catch(() => {
                RedirectUtil.triggerProtocolUrl(mailto + subject + "&body=" + body);
                this.setState({bugReportOngoing: false});
            });
    }

    collectSystemInformation = () => {
        const client = new ClientJS();
        // console.log(client);
        return (
            "\n\nBrowser:\n\tUserAgent: " +
            navigator.userAgent +
            "\n\tBrowser: " +
            client.getBrowser().name +
            "\n\tBrowser Version: " +
            client.getBrowser().version +
            "\n\tEngine: " +
            client.getEngine().name +
            "\n\tEngine Version: " +
            client.getEngine().version +
            "\n\tLangauge: " +
            navigator.language +
            "\n\tPlugins: " +
            JSON.stringify(navigator.plugins, null, "\t") +
            "\n\tMimeTypes: " +
            JSON.stringify(navigator.mimeTypes, null, "\t") +
            "\n\nSystem: " +
            "\n\tOS: " +
            client.getOS().name +
            "\n\tOS Version: " +
            client.getOS().version +
            "\n\tCPU: " +
            client.getCPU().architecture +
            "\n\tScreen: " +
            window.screen.width +
            "x" +
            window.screen.height +
            "(avail. " +
            window.screen.availWidth +
            "x" +
            window.screen.availHeight +
            ") Color-" +
            window.screen.colorDepth +
            " Pixel-" +
            window.screen.pixelDepth +
            "\n\tTimezone: " +
            (moment().zoneName() || "UTC " + moment().format("Z"))
        );
    };

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isSlim() {
        return this.state.layoutMode === "slim";
    }

    onHashChange = (e) => {
        if (e.oldURL !== e.newURL) {
            RedirectUtil.handleHashChange();
        }
    };

    createMenuFleet() {
        this.menuFleet = [
            {
                label: this.props.t("gen_ownEquipment"),
                icon: "home",
                visible: roleIdHasPermission(
                    this.state.userRole,
                    Permissions.view,
                    Features.own_equipment
                ),
                items: [
                    {
                        label: this.props.t("gen_dashboard"),
                        icon: "dashboard",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.dashboard
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_DASHBOARD.hash
                    },
                    {
                        label: this.props.t("gen_trailer"),
                        icon: "books",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.trailer
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_TRAILER.hash
                    },
                    {
                        label: this.props.t("gen_ticket"),
                        icon: "assignment",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.ticket
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_TICKET.hash
                    },
                    {
                        label: this.props.t("gen_order"),
                        icon: "shop",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.order
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_ORDER.hash
                    },
                    {
                        label: this.props.t("gen_inspection"),
                        icon: "perm_data_setting",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.inspection
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_INSPECTION.hash
                    },
                    {
                        label: this.props.t("gen_damage"),
                        icon: "list",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.damage
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_DAMAGE.hash
                    },
                    {
                        label: this.props.t("gen_debtor"),
                        icon: "person_pin",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.debtor
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_DEBTOR.hash
                    },
                    {
                        label: this.props.t("gen_report"),
                        icon: "pie_chart",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.report
                        ),
                        url: RedirectUtil.pathList.LKWWALTER_REPORT.hash
                    }
                ]
            },
            {
                label: this.props.t("gen_partner"),
                icon: "store",
                visible: roleIdHasPermission(
                    this.state.userRole,
                    Permissions.view,
                    Features.truck_buddy
                ),
                badge:
                    this.state.partnerNewTicketCount > 0 &&
                    roleIdHasPermission(
                        this.state.userRole,
                        Permissions.view,
                        Features.partner_ticket
                    )
                        ? this.state.partnerNewTicketCount
                        : null,
                items: [
                    {
                        label: this.props.t("gen_partnerTicket"),
                        icon: "assignment",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.partner_ticket
                        ),
                        url: RedirectUtil.pathList.PARTNER_TICKET.hash
                    },
                    {
                        label: this.props.t("gen_partnerOrder"),
                        icon: "shop",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.partner_order
                        ),
                        url: RedirectUtil.pathList.PARTNER_ORDER.hash
                    }
                ]
            },
            {
                label: this.props.t("gen_data"),
                icon: "library_books",
                visible: roleIdHasPermission(
                    this.state.userRole,
                    Permissions.view,
                    Features.settings,
                    Features.address
                ),
                items: [
                    {
                        label: this.props.t("gen_address"),
                        icon: "contacts",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.address
                        ),
                        url: RedirectUtil.pathList.DATA_ADDRESS.hash
                    },
                    {
                        label: this.props.t("gen_management"),
                        icon: "settings",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.management_user,
                            Features.management_translation,
                            Features.management_inspection,
                            Features.management_shipment
                        ),
                        items: [
                            {
                                label: this.props.t("management_user"),
                                icon: "people",
                                visible: roleIdHasPermission(
                                    this.state.userRole,
                                    Permissions.view,
                                    Features.management_user
                                ),
                                url: RedirectUtil.pathList.DATA_MANAGEMENT_USER.hash
                            },
                            {
                                label: this.props.t("management_translation"),
                                icon: "translate",
                                visible: roleIdHasPermission(
                                    this.state.userRole,
                                    Permissions.view,
                                    Features.management_translation
                                ),
                                url: RedirectUtil.pathList.DATA_MANAGEMENT_TRANSLATION.hash
                            },
                            {
                                label: this.props.t("management_shipment"),
                                icon: "view_week",
                                visible: roleIdHasPermission(
                                    this.state.userRole,
                                    Permissions.view,
                                    Features.management_shipment
                                ),
                                url: RedirectUtil.pathList.DATA_MANAGEMENT_SHIPMENT.hash
                            },
                            {
                                label: this.props.t("management_settings"),
                                icon: "tune",
                                visible: roleIdHasPermission(
                                    this.state.userRole,
                                    Permissions.view,
                                    Features.management_settings
                                ),
                                url: RedirectUtil.pathList.DATA_MANAGEMENT_SETTINGS.hash
                            },
                            {
                                label: this.props.t("management_news"),
                                icon: "edit",
                                visible: roleIdHasPermission(
                                    this.state.userRole,
                                    Permissions.view,
                                    Features.management_news
                                ),
                                url: RedirectUtil.pathList.DATA_MANAGEMENT_NEWS.hash
                            },
                            {
                                label: this.props.t("management_tasks"),
                                icon: "checked",
                                visible: roleIdHasPermission(
                                    this.state.userRole,
                                    Permissions.view,
                                    Features.management_tasks
                                ),
                                url: RedirectUtil.pathList.DATA_MANAGEMENT_TASKS.hash
                            }
                        ]
                    }
                ]
            },
            {
                label: this.props.t("gen_monitoring"),
                icon: "warning",
                visible: roleIdHasPermission(
                    this.state.userRole,
                    Permissions.view,
                    Features.monitoring_overview
                ),
                disabled: false,
                items: [
                    {
                        label: this.props.t("monitoring_overview"),
                        icon: "view_module",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.monitoring_overview
                        ),
                        disabled: false,
                        url: RedirectUtil.pathList.MONITORING_OVERVIEW.hash
                    }
                ]
            },
            {
                label: this.props.t("gen_basedata"),
                icon: "av_timer",
                visible:
                    roleIdHasPermission(
                        this.state.userRole,
                        Permissions.view,
                        Features.development
                    ) &&
                    roleIdHasPermission(
                        this.state.userRole,
                        Permissions.view,
                        Features.basedata_trailertype,
                        Features.basedata_trailerstate,
                        Features.basedata_damagestate,
                        Features.basedata_ticketstate
                    ),
                disabled: true,
                items: [
                    {
                        label: this.props.t("basedata_trailerType"),
                        icon: "merge_type",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.basedata_trailertype
                        ),
                        disabled: true,
                        url: RedirectUtil.pathList.BASEDATA_TRAILERTYPE.hash
                    },
                    {
                        label: this.props.t("basedata_trailerState"),
                        icon: "merge_type",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.basedata_trailerstate
                        ),
                        disabled: true,
                        url: RedirectUtil.pathList.BASEDATA_TRAILERSTATE.hash
                    },
                    {
                        label: this.props.t("basedata_damageState"),
                        icon: "merge_type",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.basedata_damagestate
                        ),
                        disabled: true,
                        url: RedirectUtil.pathList.BASEDATA_DAMAGESTATE.hash
                    },
                    {
                        label: this.props.t("basedata_ticketState"),
                        icon: "merge_type",
                        visible: roleIdHasPermission(
                            this.state.userRole,
                            Permissions.view,
                            Features.basedata_ticketstate
                        ),
                        disabled: true,
                        url: RedirectUtil.pathList.BASEDATA_TICKETSTATE.hash
                    }
                ]
            }
        ];
    }

    checkForMobile = (hash) => {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                navigator.userAgent || navigator.vendor || window.opera
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                (navigator.userAgent || navigator.vendor || window.opera).substr(0, 4)
            )
        ) {
            if (
                !hash.startsWith("#/smartview") &&
                !(window.location.search.includes("no-smartview") && window.innerWidth >= 1024)
            ) {
                window.location =
                    window.location.origin + "/" + RedirectUtil.pathList.SMARTVIEW.hash;
            }
        }
    };

    render() {
        this.createMenuFleet();

        const layoutContainerClassName = classNames("layout-container", "menu-layout-static", {
            "layout-menu-static-icons": this.state.staticMenuDesktopType === 1,
            "layout-menu-static-inactive": this.state.staticMenuDesktopType === 2,
            "layout-menu-static-active": this.state.staticMenuMobileActive
        });
        const menuClassName = classNames("layout-menu", {
            "layout-menu-dark": this.state.darkMenu
        });

        const hash = window.location.hash;
        const isSmartView = hash.startsWith("#/smartview");
        this.checkForMobile(hash);

        return (
            <div
                className={"layout-wrapper" + (isSmartView ? " f-smartview" : "")}
                style={{height: "100%"}}
                onClick={this.onDocumentClick}
            >
                {!isSmartView && <TestToolbox />}
                {this.state.bugReportOngoing && (
                    <div className="f-bug-report-overlay">
                        <div className="f-bug-report-content">
                            <ProgressSpinner />
                            <div className="f-bug-report-text">
                                {this.props.t("bug_collecting")}
                            </div>
                        </div>
                    </div>
                )}
                <GlobalMessagesDisplay />
                <TaskMonitor />
                <AppMsgHub />
                <ProfileDialog.component />
                <AddressDialog.component />
                {this.state.jwt && !this.state.passwordExpired ? (
                    !this.state.loggedIn ? (
                        ""
                    ) : (
                        <div
                            ref={(el) => (this.layoutContainer = el)}
                            className={layoutContainerClassName}
                        >
                            <AppTopbar
                                topbarMenuActive={this.state.topbarMenuActive}
                                activeTopbarItem={this.state.activeTopbarItem}
                                onMenuButtonClick={this.onMenuButtonClick}
                                onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                                onTopbarItemClick={this.onTopbarItemClick}
                                onRightPanelButtonClick={this.onRightPanelButtonClick}
                                onBackToDesktopItemClick={this.onBackToDesktopItemClick}
                                onChangePasswordItemClick={this.onChangePasswordItemClick}
                                onReportBugItemClick={this.onReportBug}
                                isSmartView={isSmartView}
                            />

                            {!isSmartView && (
                                <div className={menuClassName} onClick={this.onMenuClick}>
                                    <ScrollPanel
                                        ref={(el) => (this.layoutMenuScroller = el)}
                                        style={{height: "100%"}}
                                    >
                                        <div className="menu-scroll-content">
                                            <AppMenu
                                                model={this.menuFleet}
                                                onMenuItemClick={this.onMenuItemClick}
                                                onRootMenuItemClick={this.onRootMenuItemClick}
                                                layoutMode={this.state.layoutMode}
                                                active={this.state.menuActive}
                                                path={window.location.hash}
                                            />
                                        </div>
                                    </ScrollPanel>
                                </div>
                            )}

                            <div className="layout-main">
                                <OnSiteMessages path={window.location.hash} clearOnUrlChange />
                                <div className="layout-content">
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_DASHBOARD.uri}
                                        exact
                                        component={Dashboard}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_TRAILER.uri}
                                        exact
                                        component={TrailerOverview}
                                    />
                                    <Route
                                        path={
                                            RedirectUtil.pathList.LKWWALTER_TRAILER_DETAIL_NEW.uri
                                        }
                                        exact
                                        component={TrailerDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_TRAILER_DETAIL.uri}
                                        exact
                                        component={TrailerDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_TICKET.uri}
                                        exact
                                        component={TicketOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_TICKET_DETAIL_NEW.uri}
                                        exact
                                        component={TicketDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_TICKET_DETAIL.uri}
                                        exact
                                        component={TicketDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_ORDER.uri}
                                        exact
                                        component={OrderOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_ORDER_DETAIL.uri}
                                        exact
                                        component={OrderDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_INSPECTION.uri}
                                        exact
                                        component={InspectionOverview}
                                    />
                                    <Route
                                        path={
                                            RedirectUtil.pathList.LKWWALTER_INSPECTION_TRAILER.uri
                                        }
                                        exact
                                        component={InspectionOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.SW2000.uri}
                                        exact
                                        component={InspectionOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_DAMAGE.uri}
                                        exact
                                        component={DamageOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_DEBTOR.uri}
                                        exact
                                        component={DebtorOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_DEBTOR_DETAIL.uri}
                                        exact
                                        component={DebtorDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.LKWWALTER_REPORT.uri}
                                        exact
                                        component={Report}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.DATA_ADDRESS.uri}
                                        exact
                                        component={AddressOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.DATA_ADDRESS_DETAIL.uri}
                                        exact
                                        component={AddressDetail}
                                    />
                                    {/* Management Routes */}
                                    <Route
                                        path={RedirectUtil.pathList.DATA_MANAGEMENT_USER.uri}
                                        component={UserOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.DATA_MANAGEMENT_TRANSLATION.uri}
                                        component={TranslationModule}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.DATA_MANAGEMENT_SHIPMENT.uri}
                                        component={ShipmentOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.DATA_MANAGEMENT_SETTINGS.uri}
                                        component={SettingsPage}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.DATA_MANAGEMENT_NEWS.uri}
                                        component={News}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.DATA_MANAGEMENT_TASKS.uri}
                                        component={Tasks}
                                    />
                                    {/* Smartview */}
                                    <Route
                                        path={RedirectUtil.pathList.SMARTVIEW.uri}
                                        exact
                                        component={SmartTrailerView}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.SMARTVIEW_ORDERS.uri}
                                        exact
                                        component={SmartOrderOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.SMARTVIEW_TICKET_DETAIL.uri}
                                        exact
                                        component={SmartTicketCreation}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.SMARTVIEW_ORDER_DETAIL.uri}
                                        exact
                                        component={SmartOrderDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.PARTNER_TICKET.uri}
                                        exact
                                        component={PartnerTicketOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.PARTNER_TICKET_DETAIL_NEW.uri}
                                        exact
                                        component={PartnerTicketDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.PARTNER_TICKET_DETAIL.uri}
                                        exact
                                        component={PartnerTicketDetail}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.PARTNER_ORDER.uri}
                                        exact
                                        component={PartnerOrderOverview}
                                    />
                                    <Route
                                        path={RedirectUtil.pathList.PARTNER_ORDER_DETAIL.uri}
                                        exact
                                        component={PartnerOrderDetail}
                                    />
                                    {/* Monitoring */}
                                    <Route
                                        path={RedirectUtil.pathList.MONITORING_OVERVIEW.uri}
                                        exact
                                        component={MonitorOverview}
                                    />
                                </div>
                            </div>

                            <AppRightPanel
                                expanded={this.state.rightPanelActive}
                                onContentClick={this.onRightPanelClick}
                            />

                            <div className="layout-mask" />
                            <AppFooter />
                        </div>
                    )
                ) : (
                    <Login isSmartView={isSmartView} />
                )}
                {(this.state.passwordChangeDialogVisible || this.state.passwordExpired) && (
                    <DialogChangePassword
                        onHide={() => this.setState({passwordChangeDialogVisible: false})}
                        forceChange={this.state.passwordExpired}
                    />
                )}
                {this.state.pageUpdated && (
                    <React.Suspense fallback={null}>
                        <PageUpdated />
                    </React.Suspense>
                )}
            </div>
        );
    }

    onStoreChange = (state, actionType) => {
        switch (actionType) {
            case SessionAction.LOGIN:
                if (state.jwt) {
                    const jwtParts = state.jwt.split(".");
                    let userData;
                    try {
                        userData = JSON.parse(atob(jwtParts[1]));
                    } catch (e) {
                        console.error("Failed to parse: " + state.jwt);
                    }

                    if (userData) {
                        userData.lastname = decodeURI(userData.lastname);
                        userData.firstname = decodeURI(userData.firstname);
                    } else {
                        userData = {};
                    }
                    this.setState({
                        loggedIn: true,
                        passwordExpired: userData.passwordExpired,
                        jwt: state.jwt
                    });

                    localStorage.setItem("token", state.jwt);

                    // Set the debtor user on login
                    if (
                        roleIdHasPermission(userData.role, Permissions.view, Features.debtor_FM) &&
                        userData.type === "contact" &&
                        userData.role !== Roles.developer.id
                    ) {
                        DebtorDefinition.defaultFilter.user = {
                            name: "user",
                            value: [userData.lastname + " " + userData.firstname],
                            matchMode: "oneOf"
                        };
                    }

                    if (Roles.fmAdmin || Roles.developer) {
                        TasksDefinition.defaultState.filter = TasksDefinition.defaultFilter;
                    }

                    // Set the ticket user on login
                    if (
                        roleIdHasPermission(userData.role, Permissions.view, Features.ticket_TM) &&
                        !roleIdHasPermission(userData.role, Permissions.view, Features.ticket_FM) &&
                        userData.type === "contact" &&
                        userData.role !== Roles.developer.id
                    ) {
                        TicketDefinition.defaultFilter.dispoUser = {
                            name: "dispoUser",
                            value: [userData.lastname + " " + userData.firstname],
                            matchMode: "oneOf"
                        };
                    }
                    if (
                        roleIdHasPermission(userData.role, Permissions.view, Features.ticket_FM) &&
                        userData.type === "contact" &&
                        userData.role !== Roles.developer.id
                    ) {
                        TicketDefinition.defaultFilter.locationOwner = {
                            name: "locationOwner",
                            value: ["FM-KUF", "FM-WND"],
                            matchMode: "in"
                        };
                    }

                    if (
                        roleIdHasPermission(userData.role, Permissions.view, Features.order_TM) &&
                        userData.type === "contact" &&
                        userData.role !== Roles.developer.id
                    ) {
                        OrderDefinition.defaultFilter.userName = {
                            name: "userName",
                            value: [userData.lastname + " " + userData.firstname],
                            matchMode: "oneOf"
                        };
                    }

                    if (
                        [Roles.wsAdmin.id, Roles.wsUser.id].includes(userData.role) &&
                        userData.addressUUID
                    ) {
                        AddressRequest.loadAddress(userData.addressUUID).then((address) => {
                            TicketDefinition.defaultDetailState.element.truckerUUID =
                                userData.addressUUID;
                            TicketDefinition.defaultDetailState.element.truckerKey =
                                address.shortcut;
                            TicketDefinition.defaultDetailState.element.truckerName = address.name1;
                            TicketDefinition.defaultDetailState.element.truckerReferenceID =
                                address.referenceID;
                            if (
                                window.location.hash ===
                                RedirectUtil.pathList.LKWWALTER_TICKET_DETAIL_NEW
                            ) {
                                this.store.dispatch(
                                    TicketAction.create(
                                        ChangeType.UPDATE,
                                        TicketAction.DETAIL_TICKET,
                                        {
                                            detail: {
                                                element: {
                                                    truckerUUID: userData.addressUUID,
                                                    truckerKey: address.shortcut,
                                                    truckerName: address.name1,
                                                    truckerReferenceID: address.referenceID
                                                }
                                            }
                                        }
                                    )
                                );
                            }
                            // ----- Smartview -----
                            SmartviewDefinition.defaultTicketState.element.truckerUUID =
                                userData.addressUUID;
                            SmartviewDefinition.defaultTicketState.element.truckerKey =
                                address.shortcut;
                            SmartviewDefinition.defaultTicketState.element.truckerName =
                                address.name1;
                            SmartviewDefinition.defaultTicketState.element.truckerReferenceID =
                                address.referenceID;
                            if (
                                window.location.hash ===
                                RedirectUtil.pathList.LKWWALTER_TICKET_DETAIL_NEW
                            ) {
                                this.store.dispatch(
                                    SmartviewAction.create(
                                        ChangeType.UPDATE,
                                        SmartviewAction.TICKET_DATA,
                                        {
                                            ticket: {
                                                element: {
                                                    truckerUUID: userData.addressUUID,
                                                    truckerKey: address.shortcut,
                                                    truckerName: address.name1,
                                                    truckerReferenceID: address.referenceID
                                                }
                                            }
                                        }
                                    )
                                );
                            }
                        });
                    }

                    // Check for tbPartner role an do redirect to truck buddy ticket
                    if (
                        Roles.tbPartner.id.includes(userData.role) &&
                        !window.location.toString().includes("#/lkwwalter/trailer-detail/")
                    ) {
                        const url = RedirectUtil.pathList.PARTNER_TICKET.hash;
                        RedirectUtil.redirect(url);
                    }

                    // Retrieve contact uuid for the user if it exists
                    AddressRequest.searchContact({
                        name: "userUUID",
                        value: userData.uuid,
                        matchMode: "="
                    })
                        .then((response) => {
                            if (response && response.length) {
                                this.store.dispatch(
                                    SessionAction.create(
                                        ChangeType.UPDATE,
                                        SessionAction.INFORMATION,
                                        {
                                            contact: response[0]
                                        }
                                    )
                                );
                            }
                        })
                        .catch((err) =>
                            ErrorUtil.handleError("msg_couldNotFindContact", err, false)
                        );

                    this.store.dispatch(
                        SessionAction.create(ChangeType.UPDATE, SessionAction.INFORMATION, {
                            user: userData.uuid,
                            expires: userData.exp,
                            email: userData.email,
                            firstname: userData.firstname,
                            lastname: userData.lastname,
                            fullName: decodeURI(userData.fullName),
                            role: userData.role,
                            type: userData.type,
                            addressUUID: userData.addressUUID,
                            passwordExpired: userData.passwordExpired
                        })
                    );
                    DocumentTitleUtil.generateTitleFromURL();
                } else {
                    Store.dispatch(
                        SessionAction.create(
                            ChangeType.UPDATE,
                            SessionAction.LOGOUT,
                            SessionDefinition.defaultState
                        )
                    );
                }
                break;
            case SessionAction.LOGOUT:
                TranslationUtil.deleteStorageFiles();
                localStorage.setItem("resetStore", "true");
                localStorage.removeItem("token");

                let loginError = "";
                if (state && state.message) {
                    loginError = state.message;
                }

                this.setState({loggedIn: false, jwt: null, loginError: loginError});

                if (!state || !state.unauthorized) {
                    window.location.reload();
                }
                break;
            default:
                if (!this.state.languageLoaded && state.languageLoaded) {
                    this.onHashChange({newURL: window.location.href});
                }
                this.setState({
                    userRole: state.role,
                    languageLoaded: state.languageLoaded
                });
        }
    };

    beforeUnload = () => {
        // If translation has not been loaded correctly remove the entries
        if (!this.state.languageLoaded) {
            TranslationUtil.deleteStorageFiles();
        }
        // Save store state
        if (!localStorage.getItem("resetStore")) {
            const storeState = Store.getState();
            //Preparing store state for save
            //Trailer
            storeState.trailer.entries = [];
            storeState.trailer.selectionUUIDs = [];
            storeState.trailer.detail = TrailerDefinition.defaultDetailState;
            //Ticket
            storeState.ticket.entries = [];
            storeState.ticket.selectionUUIDs = [];
            storeState.ticket.detail = TicketDefinition.defaultDetailState;
            //Order
            storeState.order.entries = [];
            storeState.order.selectionUUIDs = [];
            storeState.order.detail = OrderDefinition.defaultDetailState;
            //Inspection
            storeState.inspection.entries = [];
            storeState.inspection.selectionUUIDs = [];
            storeState.inspection.dialog = InspectionDefinition.defaultDialogState;
            //Damage
            storeState.damage.entries = [];
            storeState.damage.selectionUUIDs = [];
            //Debtor
            storeState.debtor.selectionUUIDs = [];
            //Address
            storeState.address.entries = [];
            storeState.address.selectionUUIDs = [];
            storeState.address.detail = AddressDefinition.defaultDetailState;
            //User
            storeState.user.entries = [];
            storeState.user.selectionUUIDs = [];
            storeState.user.dialog = UserDefinition.defaultDialogState;
            //Translation
            storeState.translation.entries = [];
            storeState.translation.selectionUUIDs = [];
            storeState.translation.dialog = TranslationDefinition.defaultDialogState;
            //Shipment
            storeState.debtor.entries = [];
            storeState.debtor.detail = DebtorDefinition.defaultDetailState;
            //Debtor
            storeState.shipment.entries = [];
            storeState.shipment.selectionUUIDs = [];
            //Session
            storeState.session = SessionDefinition.defaultState;
            storeState.session.language = localStorage.getItem("language");
            //Tasks
            storeState.tasks = TasksDefinition.defaultState;
            //Settings
            storeState.settings = {};
            console.info("Saving store state:", storeState);
            localStorage.setItem("store", JSON.stringify(storeState));
        }
    };

    componentWillUnmount() {
        this.beforeUnload();
        clearInterval(this.interval);
        window.removeEventListener("beforeunload", this.beforeUnload);
    }
}

export default translate("fleet")(App);
