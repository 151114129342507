import ChangeType from "../../store/changeType/ChangeType";
import ErrorUtil from "../ErrorUtil";
import FeatureUtil from "./FeatureUtil";

class PrivateFeature {
    static NEEDED_ACTIONS = [FeatureUtil.ACTIONS.LOADING, FeatureUtil.ACTIONS.DATATABLE];

    static getDatatableObject(response) {
        const metadata = response.metadata || response.metaData;
        return response
            ? {
                  first: metadata ? metadata.offset : 0,
                  totalRecords: metadata ? metadata.total : 0,
                  entries: response.data
              }
            : {};
    }

    static async loadDatatableEntries(datatableRequest, paramData) {
        this.store.dispatch(
            this.createAction(ChangeType.UPDATE, this.actionMap[FeatureUtil.ACTIONS.LOADING], {
                loadingEntries: true,
                entries: []
            })
        );
        try {
            const response = await datatableRequest(paramData);
            this.store.dispatch(
                this.createAction(
                    ChangeType.UPDATE,
                    this.actionMap[FeatureUtil.ACTIONS.DATATABLE],
                    {
                        ...PrivateFeature.getDatatableObject(response),
                        loadingEntries: false
                    }
                )
            );
        } catch (err) {
            ErrorUtil.handleError("msg_entriesLoadFailed", err, true);
            if (!err.level || err.level === "error") {
                this.store.dispatch(
                    this.createAction(
                        ChangeType.UPDATE,
                        this.actionMap[FeatureUtil.ACTIONS.LOADING],
                        {
                            loadingEntries: false
                        }
                    )
                );
            }
        }
    }
}

class LoadEntriesFeature {
    static applyBindings(target) {
        FeatureUtil.checkCreateActionAndMap(
            "LoadEntriesFeature",
            target,
            PrivateFeature.NEEDED_ACTIONS
        );
        target.loadDatatableEntries = PrivateFeature.loadDatatableEntries.bind(target);
    }
}

export default LoadEntriesFeature;
