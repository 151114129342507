class FeatureUtil {
    static ACTIONS = {
        LOADING: "LOADING",
        DATATABLE: "DATATABLE",
        SELECTION: "SELECTION",
        DETAIL_ELEMENT: "DETAIL_ELEMENT",
        DETAIL_OPEN_ELEMENTS: "DETAIL_OPEN_ELEMENTS"
    };

    static checkFunctions(featureName, target, ...callbacks) {
        callbacks.forEach((callback) => {
            if (!target[callback]) {
                console.error(
                    featureName + ": '" + callback + "' is not defined in",
                    FeatureUtil.getClassNameOf(target)
                );
            } else if (typeof target[callback] !== "function") {
                console.error(
                    featureName + ": '" + callback + "' is not a function in",
                    FeatureUtil.getClassNameOf(target)
                );
            }
        });
    }

    static checkCreateActionAndMap(featureName, target, neededActions) {
        if (typeof target.store === "undefined" || target.store === null) {
            console.error(featureName + ": No store found in", FeatureUtil.getClassNameOf(target));
        } else if (
            typeof target.store.dispatch !== "function" ||
            typeof target.store.getState !== "function"
        ) {
            console.error(
                featureName + ": The store found in",
                FeatureUtil.getClassNameOf(target),
                "seems not to be a valid store with a dispatch and getState function."
            );
        }
        if (typeof target.createAction !== "function") {
            console.error(
                featureName + ": No 'createAction' method defined in",
                FeatureUtil.getClassNameOf(target)
            );
        }
        if (!target.actionMap) {
            console.error(
                featureName + ": The 'actionMap' is missing in",
                FeatureUtil.getClassNameOf(target)
            );
        } else if (
            neededActions.find((action) => !Object.keys(target.actionMap).includes(action))
        ) {
            console.error(
                featureName + ": Missing action",
                neededActions.find((action) => !Object.keys(target.actionMap).includes(action)),
                "in 'actionMap' of",
                FeatureUtil.getClassNameOf(target)
            );
        }
    }

    static getClassNameOf(target) {
        return target.constructor ? target.constructor.name : typeof target;
    }
}

export default FeatureUtil;
