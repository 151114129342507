import React, {Component} from "react";
import SettingsRequest from "./requests/SettingsRequest";
import ObjectUtil from "./util/ObjectUtil";
import {observeStore} from "./util/StoreUtil";
import SettingsService from "./service/settings/SettingsService";
import ChangeType from "./store/changeType/ChangeType";
import ErrorUtil from "./util/ErrorUtil";

export class AppMode extends Component {
    static defaultProps = {
        jwt: null
    };

    constructor(props) {
        super(props);

        this.state = {
            deliveryMethod: null,
            currentDeliveryMethod: null,
            loading: false
        };

        this.store = observeStore(
            SettingsService.selectFunction,
            this.onStoreChange,
            ChangeType.UPDATE
        );
    }

    //TODO: implement WebSocket solution to inform client
    refresh() {
        if (!this.state.loading && this.jwt !== null) {
            this.setState({loading: true});
            SettingsRequest.loadOrderDeliveryMethod()
                .then((result) => {
                    this.setState({
                        deliveryMethod: result.data.settingValue,
                        loading: false
                    });
                })
                .catch((err) => {
                    ErrorUtil.handleError("msg_LoadDeliveryMethodFailed", err, false);
                    this.setState({loading: false});
                });
        }
    }

    componentDidMount() {
        //this.interval = setInterval(() => this.refresh(), 60000);
    }

    fetchDeliveryMethod() {
        if (!this.state.loading && this.jwt !== null) {
            this.setState({loading: true});
            SettingsRequest.loadOrderDeliveryMethod().then((result) => {
                this.setState({
                    deliveryMethod: result.data.settingValue,
                    loading: false
                });
            });
        }
    }

    onStoreChange = (state) => {
        if (
            state &&
            typeof state.miscSettings !== "undefined" &&
            typeof state.miscSettings.orderDeliveryMethod !== "undefined" &&
            state.miscSettings.saved
        ) {
            this.setState(ObjectUtil.deepDataCopy(state));
            this.setState({
                deliveryMethod: state.miscSettings.orderDeliveryMethod.settingValue
            });
        } else {
            this.fetchDeliveryMethod();
        }
    };

    // #endregion
    render() {
        return (
            <div className="topbar-info">
                {this.state.deliveryMethod === "SMTP" ? (
                    <div className="topbar-info-container-bcm">
                        <div className="topbar-info-bcm">BCM-Mode</div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
}
