import React, {Component} from "react";
import {observeStore, sessionSelectionFunction} from "./util/StoreUtil";
import MsgHubService from "./service/msghub/MsgHubService";
import ChangeType from "./store/changeType/ChangeType";
import config from "./config";
import UriList from "./service/EndpointsList";
import MsgHubAction from "./store/msghub/MsgHubAction";
import WatchRequest from "./requests/WatchRequest";
import ChatRequest from "./requests/ChatRequest";
import TasksRequest from "./requests/TasksRequest";

export class AppMsgHub extends Component {
    constructor(props) {
        super(props);

        this.state = {
            watchList: [],
            loadingWatchList: false,
            loadingChats: false,
            loadingPMChats: false,
            loadingTasks: false,
            lastWsState: null,
            wsState: null,
            isRunning: false
        };

        this.sessionStore = observeStore(
            sessionSelectionFunction,
            this.onSessionStoreChange,
            ChangeType.UPDATE
        );

        this.msgHubStore = observeStore(
            MsgHubService.selectFunction,
            this.onMsgHubStoreChange,
            ChangeType.UPDATE
        );
    }

    componentDidMount() {
        if (!this.state.isRunning) {
            this.interval = setInterval(this.wsConnect, 2000);
            this.setState({isRunning: true});
        }
    }

    wsConnect = () => {
        const wsState = this.wsState();
        const session = this.sessionStore.getState();
        if (session.user) {
            if (this.socket === undefined || wsState === "CLOSED") {
                this.socket = new WebSocket(config.WS_URL + UriList.WS_CORE);
                this.socket.onopen = () => {
                    this.socket.send(
                        JSON.stringify({
                            msg: "connect",
                            client: {
                                uuid: session.user,
                                role: session.role
                            }
                        })
                    );
                };
                this.socket.onmessage = (e) => {
                    const msg = e.data ? JSON.parse(e.data) : "";
                    switch (msg.msg) {
                        case "reloadWatchList":
                            if (!this.state.loadingWatchList) {
                                this.setState({loadingWatchList: true}, () => this.setWatchList());
                            } else {
                                console.warn("reloadWatchList skip, already loading");
                            }
                            break;
                        case "chatReload":
                            if (!this.state.loadingChats) {
                                this.setState({loadingChats: true}, () => this.setChats());
                            } else {
                                console.warn("chatReload skip, already loading");
                            }
                            break;
                        case "pmChatReload":
                            if (!this.state.loadingPMChats) {
                                this.setState({loadingPMChats: true}, () => this.setPMChats());
                            } else {
                                console.warn("chatPMReload skip, already loading");
                            }
                            break;
                        case "tasksReload":
                            if (!this.state.loadingTasks) {
                                this.setState({loadingTasks: true}, () => this.setTasks());
                            } else {
                                console.warn("taskReload skip, already loading");
                            }
                            break;
                        case "pong":
                            break;
                        default:
                    }
                };
            }
        }
    };

    wsState() {
        if (this.socket) {
            if (this.state.lastWsState === this.socket.readyState) {
                return this.state.lastWsState;
            }
            let wsState = "CLOSED";
            switch (this.socket.readyState) {
                case 0:
                    wsState = "CONNECTING";
                    break;
                case 1:
                    wsState = "OPEN";
                    break;
                case 2:
                    wsState = "CLOSING";
                    break;
                default:
                    wsState = "UNKNOWN";
            }
            this.setState({
                lastWsState: this.socket.readyState,
                wsState: wsState
            });
            this.msgHubStore.dispatch(
                MsgHubAction.create(ChangeType.UPDATE, MsgHubAction.UPDATE, {
                    wsSate: wsState
                })
            );
        }
        return this.state.wsState;
    }

    setWatchList() {
        const session = this.sessionStore.getState();
        if (session.user !== undefined) {
            WatchRequest.isNew().then((data) => {
                if (data && data.payload) {
                    this.msgHubStore.dispatch(
                        MsgHubAction.create(ChangeType.UPDATE, MsgHubAction.UPDATE, {
                            watchList: data.payload
                        })
                    );
                    this.setState({loadingWatchList: false});
                }
            });
        }
    }

    setChats() {
        const session = this.sessionStore.getState();
        if (session.user !== undefined) {
            ChatRequest.loadNewChats(6, session.user, session.role).then((data) => {
                if (data && data.length > 0) {
                    this.msgHubStore.dispatch(
                        MsgHubAction.create(ChangeType.UPDATE, MsgHubAction.UPDATE, {
                            chats: data
                        })
                    );
                    this.setState({loadingChats: false});
                }
            });
        }
    }

    setPMChats() {
        const session = this.sessionStore.getState();
        if (session.user !== undefined) {
            ChatRequest.loadNewPVMChats(6, session.user, session.role).then((data) => {
                if (data && data.length > 0) {
                    this.msgHubStore.dispatch(
                        MsgHubAction.create(ChangeType.UPDATE, MsgHubAction.UPDATE, {
                            pmChats: data
                        })
                    );
                    this.setState({loadingPMChats: false});
                }
            });
        }
    }

    setTasks() {
        const session = this.sessionStore.getState();
        if (session.user !== undefined) {
            TasksRequest.loadNewTasks().then((data) => {
                if (data && data.length > 0) {
                    this.msgHubStore.dispatch(
                        MsgHubAction.create(ChangeType.UPDATE, MsgHubAction.UPDATE, {
                            tasks: data
                        })
                    );
                    this.setState({loadingTasks: false});
                }
            });
        }
    }

    onSessionStoreChange(sessionStore) {
        console.log("sessionState: " + JSON.stringify(sessionStore));
    }

    onMsgHubStoreChange = (msgHubStore) => {
        console.log("msgHubStore: " + JSON.stringify(msgHubStore));
    };

    render() {
        return <MsgHubService />;
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.msgHubStore.unsubscribe();
        this.sessionStore.unsubscribe();
    }
}
